<template>
  <v-container fluid class="down-top-padding">
    <v-card class="white pa-3">
      <h1 class="text-center subtitle-4 black--text">السواق</h1>
      <h5 class="text-center subtitle-4 black--text mt-1">
        العدد الكلي {{ table.totalData }}
      </h5>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn tile color="info" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="Add()">
              اضافة <v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="searching" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار" :items="table.data"
            :options.sync="tableOptions" :server-items-length="table.totalData" :loading="table.loading"
            class="elevation-1" :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-plus',
              nextIcon: 'mdi-minus',
              itemsPerPageOptions: [10, 50, 100]
            }">
            <template slot="item._id" slot-scope="props">
              {{
                (tableOptions.page - 1) * tableOptions.itemsPerPage +
                props.index +
                1
              }}
            </template>
            <template v-slot:item.date_of_contract="{ item }">
              {{ changeUtcDateToString(item.date_of_contract) }}
            </template>

            <template v-slot:item.img="{ item }">
              <show-img v-if="item.img" :content_url="content_url" :img="item.img" :width="80" :height="100"></show-img>
            </template>
            <template v-slot:item.img_id="{ item }">
              <div style="display: flex; gap: 5px">
                <show-img v-for="(img, index) in item.img_id" :content_url="content_url" :img="img" :width="50"
                  :height="50" :key="index"></show-img>
              </div>

            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="info" class="mx-2" v-bind="attrs" size="20" v-on="on" @click="Edit(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom v-if="$store.state.results.type === 'admin'">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete.open" max-width="500px">
      <v-card style="border-radius: 0">
        <v-card-title class="headline justify-center">
          هل انت متأكد من حذف هذا الحساب ؟
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete.open = false">
            الغاء
          </v-btn>
          <v-btn color="primary white--text" :loading="dialogDelete.loading" @click="deleteItemConfirm">
            حذف
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dialog -->
    <!-- Info dialog -->
    <v-dialog v-model="dialogInfo.open" max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض المعلومات</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ul class="info_list">
                  <li>
                    <div>
                      <h4>مبلغ الخصم</h4>
                      <span>{{ dialogInfo.data.discount }}</span>
                    </div>
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogInfo.open = false">
            الغاء
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--- respondMessageVue -->
    <respondMessageVue :dialogData="dialogData"></respondMessageVue>
  </v-container>
</template>

<script>
// ES modules
import driversApi from '@/api/drivers'
import respondMessageVue from '@/components/respondMessage.vue'
import showImg from '@/components/showImg.vue'
import { encrypt, decrypt } from '@/constants/cryptUrl'
import { changeUtcDateToString } from '@/constants/date'

export default {
  name: 'Stores',

  components: {
    respondMessageVue,
    showImg
  },

  data: () => ({
    content_url: null,

    handyAttachments: [],

    dialogInfo: {
      open: false,
      data: {}
    },

    addData: {
      name: null,
      description: null,
      logo: null,
      img: null,
      governorate: null,
      city: null,
      state: null,
      nearest_point: null,
      tempImg: null
    },

    Rules: {
      required: [value => !!value || 'الحقل مطلوب']
    },

    searching: null,

    tableOptions: {
      page: 1,
      itemsPerPage: 10,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortBy: [],
      sortDesc: [],
      search: null,
      firstTime: true
    },

    table: {
      search: null,
      totalData: 0,
      data: [],
      loading: true,

      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: '_id'
        },
        {
          text: 'الاسم',
          sortable: false,
          value: 'name'
        },
        { text: 'تاريخ الميلاد', sortable: false, value: 'birthday' },
        { text: 'الهاتف', sortable: false, value: 'mobile' },
        { text: 'الصورة', sortable: false, value: 'img' },
        { text: 'صور الهوية', sortable: false, value: 'img_id' },
        { text: 'العنوان', sortable: false, value: 'address' },
        {
          text: 'الملاحظات',
          sortable: false,
          value: 'note',
        },
        { text: 'العمليات', align: 'end', width: '20%', value: 'actions', sortable: false }
      ]
    },

    dialogData: {
      open: false,
      color: 'info',
      bodyText: 'test'
    },

    editedItem: {},

    dialogDelete: {
      open: false,
      loading: false
    },

    dialogEdit: {
      open: false,
      loading: false,
      isFormValid: false
    },

    dialogAdd: {
      open: false,
      loading: false,
      isFormValid: false
    }
  }),

  watch: {
    tableOptions: {
      // immediate: false,

      handler() {
        if (!this.tableOptions.firstTime) {
          this.$router.push(
            {
              query: {
                filter: encrypt({
                  page: this.tableOptions.page,
                  limit: this.tableOptions.itemsPerPage,
                  search: this.table.search
                })
              }
            },
            () => { }
          )
        }

        this.tableOptions.firstTime = false

        // this.getDataAxios();
      }
      // deep: true,
    },

    '$route.query.filter': {
      handler() {
        if (!this.$route.query.filter) {
          this.tableOptions.page = 1
          this.tableOptions.itemsPerPage = 10
          this.table.search = null
          this.searching = null
          return
        }
        const filterData = decrypt(this.$route.query.filter)

        let page = filterData.page
        let limit = filterData.limit
        let search = filterData.search

        this.tableOptions.page = +page
        this.tableOptions.itemsPerPage = +limit
        this.table.search = search
        this.searching = search

        this.getDataAxios()
      }
    },

    'table.search': {
      handler() {
        this.tableOptions.page = 1
        this.tableOptions.itemsPerPage = 10
        this.$router.push(
          {
            query: {
              filter: encrypt({
                page: this.tableOptions.page,
                limit: this.tableOptions.itemsPerPage,
                search: this.table.search
              })
            }
          },
          () => { }
        )
      }
    }
  },

  created() {
    this.getDataAxios()
  },
  methods: {
    async getDataAxios() {
      this.table.loading = true
      let search = this.table.search
      let page = this.tableOptions.page
      let limit = this.tableOptions.itemsPerPage

      if (!search) {
        search = ''
      }

      const response = await driversApi.get({
        page,
        limit,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogFunction(response.data.message, '#FF5252')
      } else {
        this.table.loading = false
        this.table.data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    Add() {
      this.$router.push('show/add')
    },

    Edit(item) {
      localStorage.setItem('driversEdit', JSON.stringify(item))
      localStorage.setItem('content_url', JSON.stringify(this.content_url))
      this.$router.push('show/edit')
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete.open = true
    },

    async deleteItemConfirm() {
      this.dialogDelete.loading = true

      const response = await driversApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogDelete.loading = false
        this.dialogDelete.open = false
        this.showDialogFunction(response.data.message, '#FF5252')
      } else {
        this.dialogDelete.loading = false
        this.dialogDelete.open = false
        this.getDataAxios()
        this.showDialogFunction(response.data.message, 'info')
      }
    },

    search() {
      this.table.search = this.searching
    },

    goToLink(id) {
      let routeData = this.$router.resolve({
        name: `customerLink`,
        params: { id: `${id}` }
      })

      window.open(routeData.href, '_blank')
    },

    showDialogFunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    changeUtcDateToString
  }
}
</script>
